import { useContext } from "react";
import { parseDate } from "@internationalized/date";
import dayjs from "dayjs";
import iso3311a2 from "iso-3166-1-alpha-2";
import PropTypes from "prop-types";
import { date, object, string } from "yup";

import { ErrorToast } from "features/report";
import { useUpdateUser } from "features/user/api";
import UserContext from "features/user/context/UserContext";

import {
  CountryPicker,
  DatePicker,
  Form,
  handleFormError,
  Input,
  Select,
  useForm,
  yupResolver,
} from "forms";
import { Button, Col, Row } from "ui";

const schema = object().shape({
  given_name: string()
    .strict()
    .max(30, "First Name cannot be longer than 30 characters")
    .required("First Name is a required field"),
  family_name: string()
    .strict()
    .max(30, "Last Name cannot be longer than 30 characters")
    .required("Last Name is a required field"),
  dob: date()
    .required()
    .max(
      new Date(new Date().setFullYear(new Date().getFullYear() - 13)),
      "You must be a minimum of 13 years old",
    )
    .typeError("Date of Birth is a required field"),
  gender: string().required("Gender is a required field"),
  country_code: string()
    .required("Country is required")
    .oneOf(iso3311a2.getCountries(), "Not a valid country"),
});

export default function OnboardingUser({ onComplete }) {
  const { user } = useContext(UserContext);
  const { mutate, isPending } = useUpdateUser();
  const {
    register,
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  return (
    <div data-testid="user-onboarding-user">
      <h2 className="capitalize mb-8 text-4xl">Tell us about yourself</h2>

      <Form
        onSubmit={handleSubmit((formData) =>
          mutate(
            {
              ...formData,
              dob: dayjs(formData.dob).format("YYYY-MM-DD"),
              country_code: iso3311a2.getCode(formData.country_code),
            },
            {
              onSuccess: onComplete,
              onError: (error, data) =>
                handleFormError({
                  error,
                  data,
                  setError,
                  errorPage: "onboarding",
                  toast: ErrorToast,
                }),
            },
          ),
        )}
      >
        <Row className="gap-5">
          <Col xs={12} md={6}>
            <Input
              {...register("given_name")}
              label="First Name"
              defaultValue={user.given_name}
              errorMessage={errors.given_name?.message}
              isInvalid={!!errors.given_name}
            />
          </Col>

          <Col>
            <Input
              {...register("family_name")}
              label="Last Name"
              defaultValue={user.family_name}
              errorMessage={errors.family_name?.message}
              isInvalid={!!errors.family_name}
            />
          </Col>
        </Row>

        <Row className="gap-5">
          <Col xs={12} md={6}>
            <DatePicker
              control={control}
              name="dob"
              defaultValue={user.dob ? parseDate(user.dob) : null}
              label="Date of Birth"
              isInvalid={!!errors.dob}
              errorMessage={errors.dob?.message}
            />
          </Col>

          <Col>
            <Select
              {...register("gender")}
              label="Gender"
              defaultSelectedKeys={[user.gender || ""]}
              errorMessage={errors.gender?.message}
              isInvalid={!!errors.gender}
            >
              <Select.SelectItem key="male">Male</Select.SelectItem>
              <Select.SelectItem key="female">Female</Select.SelectItem>
              <Select.SelectItem key="non_binary">Non Binary</Select.SelectItem>
              <Select.SelectItem key="not_to_say">Prefer not to say</Select.SelectItem>
            </Select>
          </Col>
        </Row>

        <CountryPicker
          {...register("country_code")}
          defaultSelectedKey={user.country_code}
          errorMessage={errors.country_code?.message}
          isInvalid={!!errors.country_code}
        />

        <Button
          type="submit"
          color="primary"
          isLoading={isPending}
          trackingName="continue onboarding"
          fullWidth
        >
          Continue
        </Button>
      </Form>
    </div>
  );
}

OnboardingUser.propTypes = {
  onComplete: PropTypes.func.isRequired,
};
