import { forwardRef, useCallback, useState } from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";

import Input from "forms/Input";

import { formatBytes } from "utils/helpers";

import Dropzone from "./Dropzone";

const getAcceptedFileTypesString = ({ accept, maxSize } = {}) => {
  let acceptMsg = "We can accept any file type";

  if (accept && accept.split(",").length > 0) {
    acceptMsg = `We can accept only ${accept
      .split(",")
      .map((ft) => `.${ft.split("/")[1]}`)
      .join(", ")} files`;
  }

  if (maxSize) acceptMsg += ` that are less than ${formatBytes(maxSize)} in size`;
  return acceptMsg;
};

const FileInput = forwardRef(
  (
    {
      name,
      onChange,
      onCancel,
      value,
      maxSize,
      maxFiles,
      accept,
      isInvalid,
      errorMessage,
      className,
      render,
      renderProps,
      ...inputProps
    },
    ref,
  ) => {
    const [progress, setProgress] = useState(0);
    const handleFileUpload = useCallback(
      (acceptedFiles, rejectedFiles) => {
        if (acceptedFiles.length <= 0 && rejectedFiles <= 0) return;
        if (rejectedFiles.length > 0) {
          toast.error(<div data-testid="error-toast">{rejectedFiles[0].errors[0].message}</div>, {
            autoClose: 3000,
          });
          return;
        }

        onChange(maxFiles > 1 ? acceptedFiles : acceptedFiles[0], setProgress);
      },
      [maxFiles, onChange],
    );

    const { getRootProps, getInputProps, isDragReject, isDragAccept, open } = useDropzone({
      multiple: maxFiles > 1,
      onDrop: handleFileUpload,
      maxFiles,
      maxSize,
      accept,
    });

    const Render = render;

    return (
      <div {...getRootProps({ className })} data-slot="base">
        <Render
          handleOpenFileDialog={open}
          handleCancelFileUpload={onCancel}
          isDragAccept={isDragAccept}
          isDragReject={isDragReject}
          acceptedFileTypes={getAcceptedFileTypesString({
            accept: getInputProps()?.accept,
            maxSize,
          })}
          {...{ value, isInvalid, errorMessage, progress }}
          {...renderProps}
        />

        {/* focus react hook form error */}
        <input ref={ref} className="absolute h-0" />
        <Input
          {...getInputProps({
            name,
            isInvalid,
            errorMessage,
            id: name,
            classNames: {
              inputWrapper: "hidden",
            },
            ...inputProps,
          })}
        />
      </div>
    );
  },
);
FileInput.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  value: PropTypes.string,
  maxSize: PropTypes.number,
  maxFiles: PropTypes.number,
  accept: PropTypes.shape({}),
  isInvalid: PropTypes.bool,
  errorMessage: PropTypes.string,
  className: PropTypes.string,
  render: PropTypes.elementType,
  renderProps: PropTypes.shape({}),
};
FileInput.defaultProps = {
  onCancel: undefined,
  value: "",
  maxSize: 26214400,
  maxFiles: 1,
  accept: {
    "image/png": [],
    "image/jpg": [],
    "image/jpeg": [],
    "image/webp": [],
  },
  isInvalid: false,
  errorMessage: null,
  className: "",
  render: Dropzone,
  renderProps: {},
};

export default FileInput;
