import api, { GCSApi } from "adapters/api";

const DEFAULTERROR = "Something went wrong, please try again.";

// Format the files object
export const deserializeFileData = (files) =>
  files.map(({ id, ...file }) => ({
    file_id: file.file.id,
    name: file.file.name,
    url: file.file.url,
    ...file,
  }));

export const deserializeImageData = (images) =>
  images.map(({ order_id: orderId, ...image }) => {
    const { url, name } = image.image.compressed || image.image.original;

    return {
      order_id: orderId,
      url,
      name,
      file_id: image.image.id,
      type: "image",
    };
  });

export const serializeExistingFileData = (files) =>
  files.map(({ id, file, url, selected, chosen, filtered, isNewFile, name, ...keep }, i) => ({
    ...keep,
    order_id: i,
  }));

export const serializeNewFileData = (files) =>
  files.map(({ file, url, selected, chosen, filtered, isNewFile, ...keep }, i) => ({
    ...keep,
    order_id: i,
  }));

export default function useGcsFileUpload(forFileType) {
  let controller = new AbortController();

  const onUploadProgress = (progressEvent, setProgress) => {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

    if (percentCompleted > 0 && percentCompleted < 100) setProgress(percentCompleted);
  };

  const addNewFile = async (file, fileReference) => {
    const payload = {
      file_id: fileReference.data.id,
      type: forFileType,
      name: file.name,
      description: file.name, // unless changed, the description is the name of the file.
      url: URL.createObjectURL(file),
      file,
      isNewFile: true,
    };

    return payload;
  };

  const handleFileUpload = async (file, setProgress) => {
    setProgress(1);

    const fileReference = await api.put({ url: "users/files" });

    if (!fileReference.success) {
      setProgress(0);
      return fileReference.errorDetails.message || DEFAULTERROR;
    }

    const uploadToGCS = await GCSApi.put({
      url: fileReference.data.upload_url,
      data: file,
      signal: controller.signal,
      onUploadProgress: (progressEvent) => onUploadProgress(progressEvent, setProgress),
    });

    setProgress(0);

    // If they cancel, return with no error
    if (uploadToGCS?.canceled) return {};

    if (!uploadToGCS.success) return uploadToGCS.message || DEFAULTERROR;

    return addNewFile(file, fileReference);
  };

  const handleCancelFileUpload = () => {
    controller.abort();

    // Create a new instance so we can upload again
    controller = new AbortController();
  };

  return {
    handleFileUpload,
    handleCancelFileUpload,
  };
}
