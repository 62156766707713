import { useContext } from "react";

import { stringifyFormData } from "utils/helpers";

import { client, useMutation, useQuery } from "api";

import UserContext from "./context/UserContext";

const baseURL = "users/";

const userQueries = {
  all: () => [{ url: baseURL }],
  lists: () => [{ url: baseURL, view: "list" }],
  list: (params) => [{ url: baseURL, view: "list", params }],
  details: () => [{ url: baseURL, view: "detail" }],
  detail: (id) => [{ url: baseURL, view: "detail", id: id == null ? id : String(id) }],
  tags: (id) => [{ url: baseURL, view: "detail", id: id == null ? id : String(id), type: "tags" }],
};

const useUserTags = (id) =>
  useQuery({
    queryKey: userQueries.tags(id),
    queryFn:
      () =>
      async ({ queryKey: [{ url }] }) => {
        const response = await client.get(`${url}${id}/tags`);
        return response.data;
      },
  });

const useUpdateUser = () => {
  const { setUser } = useContext(UserContext);
  return useMutation({
    mutationFn: (data) =>
      client.patch(baseURL, stringifyFormData(data, ["icon"]), {
        headers: { "Content-Type": "multipart/form-data" },
      }),
    onSuccess: ({ data }) => {
      setUser(data);
    },
  });
};

const useUserIssueReport = () => {
  const { user } = useContext(UserContext);
  return useMutation({
    mutationFn: (data) => client.post(`${baseURL}${user.id}/issue`, data),
  });
};

const useUserReport = (id) =>
  useMutation({
    mutationFn: (data) => client.post(`${baseURL}${id}/report`, data),
  });

export { useUpdateUser, useUserIssueReport, useUserReport, userQueries, useUserTags };
