import prospectIconInverted from "images/icons/prospect100icon-inverted.svg";

import { useEffect, useState } from "react";

export default function ShopLandingPage() {
  const [viewportHeight, setViewportHeight] = useState("100vh");

  useEffect(() => {
    // Update height dynamically
    const updateHeight = () => {
      setViewportHeight(`${window.innerHeight}px`);
    };

    updateHeight(); // Set initially
    window.addEventListener("resize", updateHeight);

    return () => window.removeEventListener("resize", updateHeight);
  }, []);

  return (
    <div
      className="relative w-full flex flex-col justify-between bg-cover bg-center"
      style={{
        backgroundImage:
          "url('https://i.postimg.cc/D01NBJcq/7eae48d8-f6d1-4f73-8584-f5d6a5ff58a0.jpg')", // Replace with actual URL
        height: viewportHeight, // Dynamically set height
      }}
    >
      {/* Centered Logo */}
      <div className="absolute top-4 left-1/2 transform -translate-x-1/2">
        <img src={prospectIconInverted} alt="Oditi Logo" className="h-10" />
      </div>

      {/* Bottom Left Text */}
      <div className="absolute bottom-12 left-8 right-8 text-center sm:text-left text-white px-2 sm:px-6">
        <h1 className="font-serif  text-2xl sm:text-4xl font-medium tracking-tight capitalize">Oditi</h1>
        <p className="font-serif mt-4 mb-0 text-md sm:text-lg">
          Discover gallery quality prints from creators all over the world. New works released
          daily.
        </p>
        <p className="font-serif mt-2 text-md sm:text-lg">Coming soon.</p>
      </div>
    </div>
  );
}
