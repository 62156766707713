import { useContext } from "react";
import { parseDate } from "@internationalized/date";
import dayjs from "dayjs";
import iso3311a2 from "iso-3166-1-alpha-2";
import PropTypes from "prop-types";
import { date, object, string } from "yup";

import { ErrorToast } from "features/report";
import { useUpdateUser } from "features/user/api";
import UserContext from "features/user/context/UserContext";

import {
  CountryPicker,
  DatePicker,
  Form,
  handleFormError,
  Select,
  useForm,
  yupResolver,
} from "forms";
import { Modal } from "ui";

const schema = object().shape({
  dob: date()
    .required()
    .max(
      new Date(new Date().setFullYear(new Date().getFullYear() - 13)),
      "You must be a minimum of 13 years old",
    )
    .typeError("Date of Birth is a required field"),
  gender: string().required("Gender is a required field"),
  country_code: string()
    .required("Country is a required field")
    .oneOf(iso3311a2.getCountries(), "Not a valid country"),
});

export default function AddUserInfoModal({
  onHide,
  onSuccess,
  title,
  show = false,
  footerProps = {},
}) {
  const { user } = useContext(UserContext);
  const { mutate, isPending } = useUpdateUser();
  const {
    register,
    handleSubmit,
    control,
    reset,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleClose = () => {
    reset();
    onHide();
  };

  return (
    <Modal isOpen={show} onClose={handleClose} data-testid="add-user-info-modal">
      <Modal.ModalBody className="p-6">
        <section className="flex flex-col my-4 text-center justify-center">
          <span className="text-3xl">{title}</span>
          <span className="uppercase text-primary-600">Help us keep competitions fair</span>
        </section>

        <Form
          onSubmit={handleSubmit((formData) =>
            mutate(
              {
                ...formData,
                dob: dayjs(formData.dob).format("YYYY-MM-DD"),
                country_code: iso3311a2.getCode(formData.country_code),
              },
              {
                onSuccess,
                onError: (error, data) =>
                  handleFormError({
                    error,
                    data,
                    setError,
                    errorPage: "profile",
                    toast: ErrorToast,
                  }),
              },
            ),
          )}
        >
          <DatePicker
            control={control}
            name="dob"
            defaultValue={user.dob ? parseDate(user.dob) : null}
            label="Date of Birth"
            isInvalid={!!errors.dob}
            errorMessage={errors.dob?.message}
          />

          <Select
            label="Gender"
            {...register("gender")}
            defaultSelectedKeys={[user.gender || ""]}
            errorMessage={errors.gender?.message}
            isInvalid={!!errors.gender}
          >
            <Select.SelectItem key="male">Male</Select.SelectItem>
            <Select.SelectItem key="female">Female</Select.SelectItem>
            <Select.SelectItem key="non_binary">Non Binary</Select.SelectItem>
            <Select.SelectItem key="not_to_say">Prefer not to say</Select.SelectItem>
          </Select>

          <CountryPicker
            {...register("country_code")}
            defaultSelectedKey={user.country_code}
            errorMessage={errors.country_code?.message}
            isInvalid={!!errors.country_code}
          />

          <Modal.ModalActionsFooter
            onCancel={handleClose}
            cancelProps={{
              variant: "light",
            }}
            confirmProps={{
              type: "submit",
              isLoading: isPending,
            }}
            {...footerProps}
          />
        </Form>
      </Modal.ModalBody>
    </Modal>
  );
}

AddUserInfoModal.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  show: PropTypes.bool,
  footerProps: PropTypes.shape({}),
};
