import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { useDisclosure } from "@nextui-org/react";
import PropTypes from "prop-types";
import { useController } from "react-hook-form";
import { twMerge } from "tailwind-merge";

import { Card, CropModal, Image, Tooltip } from "ui";

import { objectUrlToFile } from "utils/helpers";

import FileInput from "./FileInput";

function PreviewThumbnail({
  value,
  label,
  acceptedFileTypes,
  removeThumbnail,
  isInvalid = false,
  isDragReject = false,
}) {
  if (value) {
    return (
      <Card className="border-none aspect-4/3" shadow="none">
        <Image
          src={value instanceof File ? URL.createObjectURL(value) : value}
          data-testid="preview-card-image"
        />

        <Card.CardHeader className="absolute top-0">
          <Tooltip content="Choose another thumbnail" placement="right">
            <CloseIcon
              className="bg-content1 rounded-full text-foreground p-1 !size-8 shadow-lg"
              role="button"
              onClick={removeThumbnail}
            />
          </Tooltip>
        </Card.CardHeader>
      </Card>
    );
  }

  return (
    <div
      className={twMerge(
        "border border-dashed border-2 border-foreground p-8 w-full flex flex-col items-center justify-center gap-2 aspect-4/3 transition-colors duration-300 ease-in-out rounded-3xl bg-content2 hover:bg-focus",
        (isDragReject || isInvalid) && "bg-danger-100 !text-danger border-danger",
      )}
      data-role="form-error-component"
      role="button"
      tabIndex={0}
    >
      <div className="inline-flex gap-1 items-center mx-auto relative ">
        <AddIcon />
        <span className="after:content-['*'] after:text-danger after:ml-0.5 rtl:after:ml-[unset] rtl:after:mr-0.5 capitalize">
          {label}
        </span>
      </div>
      <p
        className={twMerge(
          "text-primary-300 block mb-0 max-w-[300px]",
          (isDragReject || isInvalid) && "!text-danger-400",
        )}
      >
        {acceptedFileTypes}.
      </p>
    </div>
  );
}
PreviewThumbnail.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  acceptedFileTypes: PropTypes.string.isRequired,
  removeThumbnail: PropTypes.func.isRequired,
  isInvalid: PropTypes.bool,
  isDragReject: PropTypes.bool,
};

export default function ControlledThumbnailInput({
  name,
  control,
  label = "Add Thumbnail",
  defaultValue = "",
  thumbnailProps = {},
  cropProps = {},
  ...fileProps
}) {
  const [file, setFile] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    field: { onChange, ...field },
  } = useController({
    name,
    control,
    defaultValue,
  });

  return (
    <div className="w-full sm:w-80">
      <FileInput
        render={PreviewThumbnail}
        onChange={(newFile) => {
          setFile(newFile);
          onOpen();
        }}
        renderProps={{
          label,
          removeThumbnail: (e) => {
            e.stopPropagation();
            onChange(null);
          },
          ...thumbnailProps,
        }}
        aria-label={label}
        {...field}
        {...fileProps}
      />

      <CropModal
        onSuccess={async (croppedSrc) => {
          onChange(await objectUrlToFile(croppedSrc, file.name));
        }}
        show={isOpen}
        onHide={onClose}
        src={isOpen ? URL.createObjectURL(file) : ""}
        {...cropProps}
      />
    </div>
  );
}
ControlledThumbnailInput.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.shape({}).isRequired,
  label: PropTypes.string,
  defaultValue: PropTypes.string,
  thumbnailProps: PropTypes.shape({}),
  cropProps: PropTypes.shape({}),
};
