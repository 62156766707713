import { useContext } from "react";

import { UserContext } from "features/user";

import { client, useMany, useMutation, useOne, useQuery, useQueryClient } from "api";

const baseURL = "briefs/";

const briefQueries = {
  all: () => [{ url: baseURL }],
  lists: () => [{ url: baseURL, view: "list" }],
  list: (params) => [{ url: baseURL, view: "list", params }],
  details: () => [{ url: baseURL, view: "detail" }],
  detail: (id) => [{ url: baseURL, view: "detail", id: id == null ? id : String(id) }],
  tags: (id) => [{ url: baseURL, view: "detail", id: id == null ? id : String(id), type: "tags" }],
};

const useBriefs = (params) => useMany({ queryKey: briefQueries.list(params) });
const useBrief = (id) => {
  const { data: briefData, ...rest } = useOne({ queryKey: briefQueries.detail(id) });
  const brief = briefData ?? {};
  return {
    data: brief,
    ...rest,
  };
};
const useBriefTags = (id) =>
  useQuery({
    queryKey: briefQueries.tags(id),
    queryFn:
      () =>
      async ({ queryKey: [{ url }] }) => {
        const response = await client.get(`${url}${id}/tags`);
        return response.data;
      },
  });

const useEnterBriefMutations = () => {
  const queryClient = useQueryClient();
  const { user } = useContext(UserContext);

  return {
    enterBrief: useMutation({
      mutationFn: ({ id }) => client.put(`briefs/${id}/enter/`),
      onSuccess: ({ data: brief }) => {
        queryClient.setQueryData(briefQueries.detail(brief.id), brief);
      },
    }),
    leaveBrief: useMutation({
      mutationFn: ({ id, reason }) => client.put(`briefs/${id}/enter/`, { not_interested: reason }),
      onSuccess: ({ data: brief }) => {
        queryClient.invalidateQueries({
          queryKey: briefQueries.list({ username: user.username }),
        });
        queryClient.setQueryData(briefQueries.detail(brief.id), brief);
      },
    }),
  };
};

const useBriefMutations = () => {
  const queryClient = useQueryClient();
  const { user } = useContext(UserContext);

  const createTags = useMutation({
    mutationFn: ({ id, data }) => client.put(`briefs/${id}/tags`, data),
  });

  const createBrief = useMutation({
    mutationFn: ({ data }) =>
      client.post(`organisations/${user.organisation.id}/briefs`, data, {
        headers: { "Content-Type": "multipart/form-data" },
      }),
    onSuccess: ({ data: brief }) => {
      queryClient.setQueryData(briefQueries.detail(brief.id), brief);
      queryClient.invalidateQueries({ queryKey: briefQueries.lists() });
    },
  });

  const updateBrief = useMutation({
    mutationFn: ({ id, data }) =>
      client.patch(`briefs/${id}`, data, { headers: { "Content-Type": "multipart/form-data" } }),
    onSuccess: ({ data: brief }) => {
      queryClient.setQueryData(briefQueries.detail(brief.id), brief);
      queryClient.invalidateQueries({ queryKey: briefQueries.lists() });
    },
  });

  return {
    createBrief: {
      mutate: async ({ data, tags = {} }, { onSuccess, onError } = {}) => {
        try {
          const res = await createBrief.mutateAsync({ data });

          if (Object.keys(tags).length > 0)
            await createTags.mutateAsync({ id: res.data.id, data: tags });

          onSuccess(res);
        } catch (err) {
          onError(err);
        }
      },
      isPending: createBrief.isPending || createTags.isPending,
    },
    updateBrief: {
      mutate: async ({ id, data, tags = {} }, { onSuccess, onError } = {}) => {
        try {
          const res = await updateBrief.mutateAsync({ id, data });

          if (Object.keys(tags).length > 0)
            await createTags.mutateAsync({ id: res.data.id, data: tags });

          onSuccess(res);
        } catch (err) {
          onError(err);
        }
      },
      isPending: updateBrief.isPending || createTags.isPending,
    },
  };
};

export {
  useBrief,
  useBriefs,
  useEnterBriefMutations,
  useBriefMutations,
  briefQueries,
  useBriefTags,
};
